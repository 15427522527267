import React from "react"
import TwitterCard from "./twitter-card"

export default function InnerPageTwitterLayout({children}) {
  return ( 
    <div className="row mb-2">
        <div className="col-md-9">
            {children}
        </div>
        <div className="col-md-3">
            <TwitterCard tweetCount="3"></TwitterCard>
        </div>
    </div>      
  );
}

import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicyConductPlayers() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Code of Conduct - Players <a href="downloads/LCJFC Code of Conduct Young Players.pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">
<h5>We all have a responsibility to promote high standards of behaviour in the game. </h5>
<p>As a player, you have a big part to play. That’s why The FA is asking every player to follow a Respect Code of Conduct. 
When playing football, I will:
<ul>
<li>Always play to the best of my ability </li>
<li>Play fairly – I won’t cheat, complain or waste time </li>
<li>Respect my team-mates, the other team, the referee or my coach/manager </li>
<li>Play by the rules, as directed by the referee </li>
<li>Shake hands with the other team and referee at the end of the game </li>
<li>Listen and respond to what my coach/ team manager tells me </li>
<li>Talk to someone I trust or the club welfare officer if I’m unhappy about anything at my club. </li>
</ul>
</p>
<p>
I understand that if I do not follow the Code, any/all of the following actions may be taken by my club, County FA or The FA: 
I may: 
<ul>
<li>Be required to apologise to my team-mates, the other team, referee or team manager </li>
<li>Receive a formal warning from the coach or the club committee </li>
<li>Be dropped or substituted </li>
<li>Be suspended from training • Be required to leave the club. </li>
</ul>
</p>
<p>
In addition: 
<ul>
<li>My club, County FA or The FA may make my parent or carer aware of any infringements of the Code of Conduct </li>
<li>The FA/County FA could impose a fine and suspension against my club. </li>
</ul>
</p>
</div> 
            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}

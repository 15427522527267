import React, {useEffect} from "react"

export default function TwitterCard(props) {

    const tweetCount = props.tweetCount || 3;

    useEffect(() => {
        const scriptTag = document.createElement('script');
      
        scriptTag.src = 'https://platform.twitter.com/widgets.js';
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
      
        return () => {
            document.body.removeChild(scriptTag);
        }
    })

    return (
        <div className="card shadow-sm">
            <div className="card-header">Tweets <small><a href="https://twitter.com/LeedsCityJnrs" title="tweets by ‎@LeedsCityJnrs">by ‎@LeedsCityJnrs</a></small></div>
            <div className="card-body">
            <a className="twitter-timeline" href="https://twitter.com/LeedsCityJnrs?ref_src=twsrc%5Etfw" data-chrome="noheader nofooter noborders" data-tweet-limit={tweetCount}>Tweets by LeedsCityJnrs</a>
            </div>
        </div>
    );
}
